import { SEPARATOR } from "utils/constants";

export const ActivityNames = {
	Login: "Login",
	Logout: "Logout",
	Create: "Create",
	Update: "Update",
	Delete: "Delete",
	Add: "Add",
	Remove: "Remove",
	Email: "Email",
	Feedback: "Feedback",
	NpsScore: "NPS Score",
	Upsell: "Upsell",
	Unknown: "Unknown",
	Search: "Search",
	XAPI_Initialized: "Initialise",
	XAPI_Attempted: "Attempt",
	XAPI_Played: "Play",
	XAPI_Paused: "Pause",
	XAPI_Watched: "Watch",
	XAPI_Answered: "Answer",
	XAPI_CompletedVideo: "Complete",
	XAPI_CompletedAssessment: "Complete",
	XAPI_Terminated: "Terminate",
	XAPI_Opened: "Open",
	XAPI_Experienced: "Experience",
	XAPI_Downloaded: "Download",
	XAPI_Progressed: "Progressed",
	XAPI_Other: "Other XAPI"
};

export const ActivityDescriptions = {
	Login: "logged in",
	Logout: "logged out",
	LogoutTimeout: "was logged out through inactivity",
	CompanyCreate: "created the Company <em>'#'</em>",
	CompanyUpdate: "updated the Company <em>'#'</em>",
	CompanyDelete: "deleted the Company <em>'#'</em>",
	UserCreate: "created the User <em>'#'</em>",
	UserCreateBulk: "uploaded and created <em>#</em> users",
	UserCreateInCompany: "created the User <em>'#'</em> in <em>'#'</em>",
	UserUpdate: "updated the User <em>'#'</em>",
	UserDelete: "deleted the User <em>'#'</em>",
	CourseCreate: "created the Course <em>'#'</em>",
	CourseUpdate: "updated the Course <em>'#'</em>",
	CourseDelete: "deleted the Course <em>'#'</em>",
	CourseModuleCreate: "created the Course Module <em>'#'</em>",
	CourseModuleUpdate: "updated the Course Module <em>'#'</em>",
	CourseModuleDelete: "deleted the Course Module <em>'#'</em>",
	CourseModuleContentUpload: "uploaded new content for Course Module <em>'#'</em>",
	CourseModuleContentCreate: "created content in Course Module <em>'#'</em>",
	CourseModuleContentUpdate: "updated content in Course Module <em>'#'</em>",
	CourseModuleContentDelete: "deleted content from Course Module <em>'#'</em>",
	CourseCustomDataCreate: "created custom data for Course <em>'#'</em>",
	CourseCustomDataUpdate: "updated custom data in Course <em>'#'</em>",
	CourseCustomDataDelete: "deleted custom data from Course <em>'#'</em>",
	CompanyAddCourse: "added <em>'#'</em> to the Company <em>'#'</em>",
	CompanyRemoveCourse: "removed <em>'#'</em> from the Company <em>'#'</em>",
	CourseAddUser: "added <em>'#'</em> to the Course <em>'#'</em>",
	CourseRemoveUser: "removed <em>'#'</em> from the Course <em>'#'</em>",
	CourseAddCourseModule: "added <em>'#'</em> to the Course <em>'#'</em>",
	CourseRemoveCourseModule: "removed <em>'#'</em> from the Course <em>'#'</em>",
	OfferCreate: "created the Offer <em>'#'</em>",
	OfferUpdate: "updated the Offer <em>'#'</em>",
	OfferDelete: "deleted the Offer <em>'#'</em>",
	CategoryCreate: "created the Course Category <em>'#'</em>",
	CategoryUpdate: "updated the Course Category <em>'#'</em>",
	CategoryDelete: "deleted the Course Category <em>'#'</em>",
	JourneyCreate: "created the Journey <em>'#'</em> in Course Category <em>'#'</em>",
	JourneyUpdate: "updated the Journey <em>'#'</em> in Course Category <em>'#'</em>",
	JourneyDelete: "deleted the Journey <em>'#'</em> from Course Category <em>'#'</em>",
	JourneyAddCourse: "added <em>'#'</em> to the Journey <em>'#'</em> in Course Category <em>'#'</em>",
	JourneyRemoveCourse: "removed <em>'#'</em> from the Journey <em>'#'</em> in Course Category <em>'#'</em>",
	CourseTypeCreate: "created the Custom Course type <em>'#'</em>",
	CourseTypeUpdate: "updated the Custom Course type <em>'#'</em>",
	CourseTypeDelete: "deleted the Custom Course type <em>'#'</em>",
	UserRoleCreate: "created the User Role <em>'#'</em>",
	UserRoleUpdate: "updated the User Role <em>'#'</em>",
	UserRoleDelete: "deleted the User Role <em>'#'</em>",
	ThemeCreate: "created the Theme <em>'#'</em>",
	ThemeUpdate: "updated the Theme <em>'#'</em>",
	ThemeDelete: "deleted the Theme <em>'#'</em>",
	NotificationCreate: "created the Notification <em>'#'</em>",
	NotificationUpdate: "updated the Notification <em>'#'</em>",
	NotificationDelete: "deleted the Notification <em>'#'</em>",
	EmailRegistrationSent: "sent a Complete Registration link to <em>'#'</em>",
	EmailPasswordResetSent: "sent a Password reset link to <em>'#'</em>",
	EmailPasswordResetSentFromLogin: "sent a Password reset from the 'Forgot your Password' link on the Login page",
	EmailLoginPeriodReminderSent: "sent a 'Period between logins' reminder to <em>'#'</em>",
	EmailNudgeSent: "sent a Nudge to <em>'#'</em>, regarding <em>'#'</em>",
	EmailCongratulationSent: "sent Congratulations to <em>'#'</em>, regarding <em>'#'</em>",
	CompanyOfferProvisioned: "provisioned the Offer <em>'#'</em> to <em>'#'</em>",
	CompanyOfferUpdated: "updated the Offer <em>'#'</em> for <em>'#'</em>",
	CompanyOfferRemoved: "removed the Offer <em>'#'</em> from <em>'#'</em>",
	OpportunityCreate: "created the Upsell Opportunity <em>'#'</em>",
	OpportunityUpdate: "updated the Upsell Opportunity <em>'#'</em>",
	OpportunityDelete: "deleted the Upsell Opportunity <em>'#'</em>",
	LeftFeedback: "has left '#' feedback",
	LeftNpsScore: "has left an NPS Score (#)",
	ReportAccessUpdate: "updated the Report Access Settings for <em>'#'</em>",
	UpsellStarted: "has started the Upselling process",
	UpsellCompleted: "has completed the Upselling process",
	SendinblueAddUser: "added user <em>'#'</em> to Sendinblue",
	SendinblueUpdateUser: "updated lists in Sendinblue for <em>'#'</em>",
	SendinblueOptUser: "updated marketing consent via <em>'#'</em> ",
	SendinblueRemoveUser: "removed <em>'#'</em> from Sendinblue",
	SearchedFor: "searched for <em>'#'</em>",
	RoleCompanyUpdate: "updated Company assignments to the Role <em>'#'</em>",
	XAPI_Initialized: "initialised <em>'#'</em>",
	XAPI_Attempted: "attempted <em>'#'</em>",
	XAPI_Played: "played <em>'#'</em>",
	XAPI_Paused: "paused <em>'#'</em>",
	XAPI_Watched: "watched <em>'#'</em>",
	XAPI_Answered: "answered <em>'#'</em>",
	XAPI_CompletedVideo: "completed <em>'#'</em>",
	XAPI_CompletedAssessment: "completed <em>'#'</em>",
	XAPI_Terminated: "terminated <em>'#'</em>",
	XAPI_Opened: "opened <em>'#'</em>",
	XAPI_Experienced: "experienced <em>'#'</em>",
	XAPI_Downloaded: "downloaded <em>'#'</em>",
	XAPI_Progressed: "progressed <em>'#'</em>",
	XAPI_Other: "performed '#' on <em>'#'</em>"
};

export const getActivityClass = (activity) => {
	const activityColourMap = {
		"CompanyOfferProvisioned": "green",
		"LeftFeedback": "violet",
		"LeftNpsScore": "violet",
		"SendinblueOptUser": "orange",
		"SearchedFor": "navy",
		"XAPI_CompletedVideo": "turquoise",
		"XAPI_CompletedAssessment": "turquoise",
	};
	if (activity in activityColourMap) {
		return activityColourMap[activity];
	}
	else {
		switch (true) {
			case (activity.indexOf("Login") === 0):
			case (activity.indexOf("Logout") === 0):
				return "blue";
			case (activity.indexOf("Create") !== -1):
			case (activity.indexOf("Add") !== -1):
				return "green";
			case (activity.indexOf("Update") !== -1):
			case (activity.indexOf("Upload") !== -1):
				return "orange";
			case (activity.indexOf("Delete") !== -1):
			case (activity.indexOf("Remove") !== -1):
				return "red";
			case (activity.indexOf("Email") !== -1):
				return "purple";
			case (activity.indexOf("XAPI_") === 0):
				return "lightBlue";
			default:
				return "grey";
		}
	}
};

export const getActivityTitle = (activity) => {
	const activityTitleMap = {
		"Login": ActivityNames.Login,
		"LeftFeedback": ActivityNames.Feedback,
		"LeftNpsScore": ActivityNames.NpsScore,
		"SendinblueOptUser": ActivityNames.Update,
		"SearchedFor": ActivityNames.Search,
	};
	if (activity in activityTitleMap) {
		return activityTitleMap[activity];
	}
	else {
		switch (true) {
			case (activity.indexOf("Logout") === 0):
				return ActivityNames.Logout;
			case (activity.indexOf("Create") !== -1):
				return ActivityNames.Create;
			case (activity.indexOf("Add") !== -1):
				return ActivityNames.Add;
			case (activity.indexOf("Update") !== -1):
			case (activity.indexOf("Upload") !== -1):
				return ActivityNames.Update;
			case (activity.indexOf("Delete") !== -1):
				return ActivityNames.Delete;
			case (activity.indexOf("Remove") !== -1):
				return ActivityNames.Remove;
			case (activity.indexOf("Email") === 0):
				return ActivityNames.Email;
			case (activity.indexOf("Upsell") === 0):
				return ActivityNames.Upsell;
			case (activity.indexOf("XAPI_") === 0):
				return ActivityNames[activity];
			default:
				return ActivityNames.Unknown;
		}
	}
};

function getUserCreateDescription(object, activity) {
	if (object.indexOf(SEPARATOR) !== -1) {
		let objects = object.split(SEPARATOR);
		let result = ActivityDescriptions.UserCreateInCompany;
		for (let i = 0; i < objects.length; ++i) {
			result = result.replace("#", objects[i]);
		}
		return result;
	}
	else {
		return ActivityDescriptions[activity].replace("#", object);
	}
}

function getCourseModuleContentUploadDescription(object, activity) {
	let result = "";
	let objects = object.split(SEPARATOR);
	result += ActivityDescriptions[activity].replace("#", objects[0]);
	if (objects.length > 1) {
		result += " - " + objects[1];
		if (objects.length > 2) {
			result += " > " + objects[2];
		}
	}
	return result;
}

function getEmailDescription(object, activity) {
	let json;
	try {
		json = JSON.parse(object);
	}
	catch (e) {
		let objects = object.split(SEPARATOR);
		json = {
			To: objects[0],
			ObjectName: objects[1]
		};
	}
	return ActivityDescriptions[activity].replace("#", json.To).replace("#", json.ObjectName);
}

function getNotificationDescription(object, activity) {
	const NOTIFICATION_NAMES = {
		UserNudge: "Nudge button in Users screen",
		ReportNudge: "Nudge button in reports",
		ReportCongratulate: "Congratulate button in reports",
		EventCourseStarted: "Event - Course started",
		EventCourseCompleted: "Event - Course completed",
		ReminderNotLoggedIn: "Reminder - complete your ucSKILLS registration",
		ReminderNotConsumedContent: "Reminder - not yet consumed any content"
	};

	let result = "";
	let objects = object.split(SEPARATOR);
	result += ActivityDescriptions[activity].replace("#", NOTIFICATION_NAMES[objects[0]]);
	if (objects.length > 1) {
		//if (objects[1] in res) {
		//	result += " (" + res[objects[1]] + ")";
		//}
		//else {
			result += " for Course <em>'" + objects[1] + "'</em>";
		//}
	}
	return result;
}

function getMultiReplaceDescription(object, activity, separator) {
	if (object.indexOf(SEPARATOR) !== -1) {
		let objects = object.split(SEPARATOR);
		if (separator) {
			return ActivityDescriptions[activity].replace("#", objects[0]) + separator + objects[1];// res[objects[1]];
		}
		else {
			let result = ActivityDescriptions[activity];
			for (let i = 0; i < objects.length; ++i) {
				result = result.replace("#", objects[i]);
			}
			return result;
		}
	}
	else {
		return ActivityDescriptions[activity].replace("#", object);
	}
}

function getMiscellaneousDescription(object, activity, separator = ",") {
	if (activity in ActivityDescriptions) {
		let objects = (object.indexOf(SEPARATOR) !== -1) ? object.split(SEPARATOR) : object.split(',');
		objects = objects.map(function (curr) {
			const c = curr.trim();
			return c;// (c in res) ? res[c] : c;
		});
		const first = objects[0];
		objects.shift();
		let result = ActivityDescriptions[activity].replace("#", first);
		if (objects.length > 0) {
			result += " (" + objects.join(separator) + ")";
		}
		return result;
	}
	else {
		return ActivityDescriptions.XAPI_Other.replace('#', activity).replace("#", object);
	}
}

export const getActivityDescription = (activity, user, object) => {
	let result = "<span><strong>" + user + "</strong> ";
	switch (activity) {
		case "UserCreate":
			result += getUserCreateDescription(object, activity);
			break;
		case "CourseModuleContentUpload":
			result += getCourseModuleContentUploadDescription(object, activity);
			break;
		case "CategoryUpdate":
		case "CourseUpdate":
		case "CourseModuleUpdate":
		case "CourseModuleContentUpdate":
			result += getMultiReplaceDescription(object, activity, " - ");
			break;
		case "CompanyAddCourse":
		case "CompanyRemoveCourse":
		case "JourneyCreate":
		case "JourneyUpdate":
		case "JourneyDelete":
		case "JourneyAddCourse":
		case "JourneyRemoveCourse":
		case "CourseAddUser":
		case "CourseRemoveUser":
		case "CourseAddCourseModule":
		case "CourseRemoveCourseModule":
		case "CompanyOfferProvisioned":
		case "CompanyOfferUpdated":
		case "CompanyOfferRemoved":
			result += getMultiReplaceDescription(object, activity);
			break;
		case "EmailNudgeSent":
		case "EmailCongratulationSent":
			result += getEmailDescription(object, activity);
			break;
		case "NotificationCreate":
		case "NotificationUpdate":
		case "NotificationDelete":
			result += getNotificationDescription(object, activity);
			break;
		case "ReportAccessUpdate":
		case "RoleCompanyUpdate":
			result += getMiscellaneousDescription(object, activity, " ");
			break;
		default:
			result += getMiscellaneousDescription(object, activity);
			break;
	}
	return result + "</span>";
};