import { useEffect } from "react";
import "./Modal.scss";
import useBranding from "hooks/useBranding";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonType } from "components/Button/Button";
import { ModalButtons } from "./ModalButtons";

/**
 * @component
 * @param {MessageModalProps} props
 */
const MessageModal = ({ modalTitle, modalContent, modalButtons, closeModal, customModalButtonContent }) => {
	const { gradient, watermark } = useBranding();

	// Prevent page scrolling when the modal is open
	useEffect(() => {
		document.body.classList.add('no-scroll');

		// Cleanup when the component is unmounted
		return () => {
			document.body.classList.remove('no-scroll');
		};
	}, []);

	const renderButtons = () => {
		if (modalButtons === ModalButtons.Ok) {
			return (
				<div className="modal-buttons">
					<Button type={ButtonType.Secondary} text="Ok" onClick={closeModal} />
				</div>
			);
		}
		if (modalButtons === ModalButtons.YesNo) {
			return (
				<div className="modal-buttons">
					<Button type={ButtonType.Secondary} text="No" onClick={() => closeModal(false)} />
					<Button type={ButtonType.Primary} text="Yes" onClick={() => closeModal(true)} />
				</div>
			);
		}
		if (modalButtons === ModalButtons.Custom && customModalButtonContent) {
			return <div className="modal-buttons">{customModalButtonContent}</div>;
		}
		return null;
	};

	return (
		<div className="modal">
			<div className="modal-backdrop" onClick={() => closeModal(false)}></div>
			<div className={`modal-container ${modalButtons === ModalButtons.YesNo ? 'yesno' : ''}`}>
				<div className="modal-header">
					<h1>{modalButtons === ModalButtons.YesNo ? "Are you sure?" : modalTitle}</h1>
					{watermark && <img alt="Pod Background Detail" className="podHeader-image" src={watermark} />}
					{gradient && <span className="podHeader-grad"></span>}
					<button className="modal-close" onClick={() => closeModal(false)}><FontAwesomeIcon icon={faXmark} /></button>
				</div>
				<div className="modal-content">
					{modalContent}
					{renderButtons()}
				</div>
			</div>
		</div>
	);
};

export default MessageModal;
