import CourseDetails from "../../../components/CourseDetails/CourseDetails";


const CourseList = ({ contentItems }) => {

	return (
		<>
			{contentItems && contentItems.map(course => {
				return (
					<div key={course.Id} className="course-pod">
						<CourseDetails course={course} linkage={true} />
					</div>
				);
			})}
		</>
	);
};

export default CourseList;
