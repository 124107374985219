import useBranding from "hooks/useBranding";
import { useEffect, useState } from 'react';
import './Pod.scss';

const Pod = ({ children, title = "title", mobileOrder, sticky }) => {
	const { whitelabelled, gradient, watermark } = useBranding();
	const mobileWidth = 1024;
	const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);

	const handleResize = () => {
		setIsMobile(window.innerWidth < mobileWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const mobileOrderStyle = isMobile && mobileOrder ? { gridRow: mobileOrder } : {};

	return (
		<div className={`pod ${sticky ? 'sticky' : ''}`} style={mobileOrderStyle}>
			<div className="podHeader">
				<h1>{title}</h1>
				{watermark && <img alt="Pod Background Detail" className={`podHeader-image${whitelabelled ? "" : " default"}`} src={watermark} />}
				{gradient && <span className="podHeader-grad"></span>}
			</div>
			<div className="podBody">
				{children}
			</div>
		</div>
	);
};

export default Pod;
