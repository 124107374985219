import { useCallback } from "react";

const useScrollToPageArea = () => {
	const scroll = useCallback((elementId) => {
		if (elementId) {
			const scrollToElement = () => {
				const element = document.getElementById(elementId);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' });
					return true;
				}
				return false;
			};

			if (!scrollToElement()) {
				const observer = new MutationObserver(() => {
					if (scrollToElement()) {
						observer.disconnect();
					}
				});
				observer.observe(document.body, { childList: true, subtree: true });

				return () => {
					if (observer) {
						observer.disconnect();
					}
				};
			}
		}
		return () => { };
	}, []);

	return { scroll };
};

export default useScrollToPageArea;
