import { DataFetcher } from "components/DataFetcher";
import { useModal } from "components/Modal/context/ModalContext";
import { PackageType, packageIsScorm } from "features/Shared/ContentData/PackageType";
import useLogout from "hooks/useLogout";
import { useCallback, useEffect, useRef, useState } from "react";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import "./Player.scss";
import PlayerBreadcrumb from "./components/PlayerBreadcrumb";
import PlayerDetails from "./components/PlayerDetails";
import PlayerMedia from "./components/PlayerMedia";
import PlayerSidebar from "./components/PlayerSidebar";
import usePlayerMessageReceiver from "./hooks/usePlayerMessageReceiver";
import usePlayerResize from "./hooks/usePlayerResize";
import usePlayerTerminate from "./hooks/usePlayerTerminate";

const NoContentType = Object.freeze({
	LoggedInNotExist: 1,
	LoggedInNotEnrolled: 2,
	NotLoggedIn: 3
});

const Player = () => {
	const mediaRef = useRef();
	const location = useLocation();
	const navigate = useNavigate();
	const { logout } = useLogout(navigate);
	const { openModal } = useModal();
	const [fetchData, setFetchData] = useState({});
	const [data, setData] = useState(null);
	const expectedMessageOrigin = useRef("");
	const [sidebarPresent, setSidebarPresent] = useState(false);
	const [sidebarSections, setSidebarSections] = useState([]);
	const [selectedSection, setSelectedSection] = useState(0);
	const [detailNavigationSections, setDetailNavigationSections] = useState({});
	const {
		setReceivedPlayerWidth,
		setReceivedPlayerHeight,
		setBreadcrumbHeight,
		calculatedPlayerWidth,
		calculatedPlayerHeight
	} = usePlayerResize(window, sidebarPresent);
	
	const unloading = useRef(0);
	let blocker = useBlocker(({ currentLocation, nextLocation }) => data && data.Path.length && currentLocation.pathname !== nextLocation.pathname);
	const { postTerminate } = usePlayerTerminate(blocker, unloading, data);

	const handleOpenNoContentModal = useCallback((noContentType) => {
		let message = <div>You will be redirected to the login page. You will be returned to this page after logging in.</div>;
		switch (noContentType) {
			case NoContentType.LoggedInNotExist:
				message = <div>We are sorry. The content you have requested does not exist. You will be returned to the dashboard.</div>;
				break;
			case NoContentType.LoggedInNotEnrolled:
				message = <div>We are sorry. This content has not been assigned to you. Please contact your ucSKILLS Administrator.<br />You will be returned to the dashboard.</div>;
				break;
			default:
				break;
		} 
		const onClick = (noContentType !== NoContentType.NotLoggedIn)
			? () => navigate("/dashboard", { replace: true })
			: () => {
				localStorage.setItem("ucs-lau-anon", location.pathname + location.search + location.hash);
				logout();
			};
		openModal("", message, null, onClick);
	}, [navigate, location, logout]);

	// Effect that processes the URL hash.
	useEffect(() => {
		if (location.hash) {
			const idList = location.hash.slice(1).split('-'); // Format is #courseId-*-moduleId-*-subModuleId-*
			setFetchData({ url: `/api/v3/PlayerData/${idList[3]}/${idList[5]}` });
		}
		else {
			handleOpenNoContentModal(sessionStorage.getItem("userData") ? NoContentType.LoggedInNotExist : NoContentType.NotLoggedIn);
		}
	}, [location, handleOpenNoContentModal]);

	const handleData = useCallback((fetchedData) => {
		if (!fetchedData || !fetchedData.Path) {
			handleOpenNoContentModal(NoContentType.LoggedInNotExist);
			return;
		}
		expectedMessageOrigin.current = new URL(fetchedData.Path).origin;
		setData(fetchedData);

		if (fetchedData.TableOfContents.length) {
			let all = JSON.parse(fetchedData.TableOfContents);
			all = all.map((curr, index) => {
				curr.no = index;
				return curr;
			});
			const filteredSections = all.filter((curr) => {
				return ((curr.title.toLocaleLowerCase() !== "completed") && (!curr.title.toLocaleLowerCase().startsWith("slide")));
			});
			setSidebarSections(filteredSections);
			setDetailNavigationSections({ next: filteredSections[0] });

			// Set sidebarPresent based on whether there are sections
			setSidebarPresent(filteredSections.length > 0);
		}
	}, [handleOpenNoContentModal]);

	const handleError = useCallback((error) => {
		switch (error.Status) {
			case 400:
				if (error.Message === "NOT_ENROLLED") {
					handleOpenNoContentModal(NoContentType.LoggedInNotEnrolled);
				}
				break;
			case 404:
				handleOpenNoContentModal(NoContentType.LoggedInNotExist);
				break;
			default:
				break;
		}
	}, [handleOpenNoContentModal]);
	
	const handlePostMessage = useCallback((messageData) => {
		const path = (packageIsScorm(data.Type)) ? data.ScormData.ProxyUri : data.Path;
		const destination = mediaRef && mediaRef.current;
		if (destination && destination.contentWindow) {
			console.debug("Sending message:", messageData.action, messageData);
			console.debug("Posting message to:", path);
			destination.contentWindow.postMessage(JSON.stringify(messageData), path);
		}
		else {
			console.warn("MediaRef or destination contentWindow is null");
		}
	}, [mediaRef, data]);

	const handleSelectSection = (index) => {
		setSelectedSection(sidebarSections[index].id);
		setDetailNavigationSections({
			prev: index > 0 ? sidebarSections[index - 1] : null,
			next: index < sidebarSections.length - 1 ? sidebarSections[index + 1] : null
		});
	};

	const getSectionById = (id) => {
		return sidebarSections.findIndex((curr) => {
			return String(curr.id) === String(id);
		});
	};

	const handleMediaLoaded = () => {
		if (packageIsScorm(data.Type)) {
			return;
		}

		// Hide the TOC.
		handlePostMessage({ action: "hideToc" });

		// Request container dimensions.
		handlePostMessage({ action: "getDimensions" });

		// Highlight last accessed slide number.
		if (sidebarSections) {
			const index = sidebarSections.findIndex((curr) => {
				return curr.title === data.Current;
			});
			if (index !== -1) {
				handleSelectSection(index);
			}
		}
	};
	
	const handleClickSectionItem = (id) => {
		if (sidebarSections) {
			const index = getSectionById(id);
			if (index !== -1) {
				handleSelectSection(index);
				handlePostMessage({ action: data.Type === PackageType.xAPI_CAPTIVATE ? "setSlide" : "setSection", no: sidebarSections[index].no });
			}
		}
	};

	const markCurrentSectionAsWatched = (index) => {
		let shallowSectionsCopy = [...sidebarSections];
		shallowSectionsCopy[index].visited = 100;
		setSidebarSections(shallowSectionsCopy);
	};

	const handleSlideEnter = (messageData) => {
		if (sidebarSections) {
			console.debug("ucSKILLS::Recieved", messageData.eventName);
			let index = -1;
			if (data.Type === PackageType.xAPI_CAPTIVATE) {
				index = sidebarSections.findIndex((curr) => {
					return curr.title === messageData.slideName;
				});
			}
			else if (messageData.sectionData) {
				index = getSectionById(messageData.sectionData.Id);
			}
			if (index !== -1) {
				handleSelectSection(index);
				if (data.Type === PackageType.xAPI_CAPTIVATE) {
					markCurrentSectionAsWatched(index);
				}
			}
			else {
				console.warn("ucSKILLS: Data sent by Captivate content cannot map to the Sidebar content.", messageData);
			}
		}
	};

	const handlePlaySection = (messageData) => {
		if (messageData.sectionData) {
			const index = getSectionById(messageData.sectionData.Id);
			if (index !== -1) {
				setTimeout(() => markCurrentSectionAsWatched(index), 2000); // Give it 2 seconds before updating
			}
		}
	};

	const handleReceiveDimensions = (messageData) => {
		setReceivedPlayerWidth(messageData.boundingRect.width);
		setReceivedPlayerHeight(messageData.boundingRect.height);
	};
	
	const messageMap = {
		"CPAPI_SLIDEENTER": handleSlideEnter,
		"SECTIONSTART": handleSlideEnter,
		"PLAYSECTION": handlePlaySection,
		"DIMENSIONS": (messageData) => {
			handleReceiveDimensions(messageData);
			console.debug(messageData.boundingRect.width + " Width PlayerPage");
			console.debug(messageData.boundingRect.height + " Height PlayerPage");
		},
		"UNLOAD": postTerminate
	};

	usePlayerMessageReceiver(expectedMessageOrigin.current, messageMap, unloading);
	
	// Scrolling
	useEffect(() => {
		if (sidebarSections) {
			// Scroll to the top of the player-container on load
			const playerContainer = document.getElementById('player-container');
			if (playerContainer) {
				playerContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	}, [sidebarSections]);

	// On exiting the page...
	useEffect(() => {
		if (blocker.state === "blocked") {
			if (data.Type !== PackageType.xAPI_CAPTIVATE) {
				// Xapi and SCORM send unload messages; the response data is used to write LRS termination events.
				handlePostMessage({ action: "unload", v3: true });
			}
			else {
				// Captivate has no unload communication.
				postTerminate({});
			}
		}
	}, [blocker.state, handlePostMessage, postTerminate, data]);

	return (
		<>
			<DataFetcher fetchData={fetchData} setData={handleData} setError={handleError} />
			{data !== null && (
				<div className={`player ${sidebarPresent ? 'withSidebar' : 'withoutSidebar'}`}>
					<div id="player-container" className="player-container" style={{ width: calculatedPlayerWidth }}>
						<PlayerBreadcrumb
							course={data.Course}
							module={data.Module}
							submodule={data.SubModule.Name}
							setBreadcrumbHeight={setBreadcrumbHeight}
						/>
						<PlayerMedia
							data={data}
							mediaRef={mediaRef}
							handleMediaLoaded={handleMediaLoaded}
							calculatedPlayerHeight={calculatedPlayerHeight}
						/>
						<PlayerDetails
							previousSection={detailNavigationSections.prev}
							nextSection={detailNavigationSections.next}
							subModule={data.SubModule}
							courseLink={data.Course}
							moduleLink={data.Module}
							handleClickSectionItem={handleClickSectionItem}
						/>
					</div>
					{sidebarPresent &&
						<PlayerSidebar
							sectionsArray={sidebarSections}
							selectedSection={selectedSection}
							handleClickSectionItem={handleClickSectionItem}
							module={data.Module}
							submodule={data.SubModule}
						/>
					}
				</div>
			)}
		</>
	);

};

export default Player;
