import PercentageCircle from "components/PercentageCircle/PercentageCircle";
import "./PlayerSidebar.scss";

const PlayerSidebar = ({ sectionsArray, selectedSection, handleClickSectionItem, submodule, module }) => {

	const title = () => {
		if (submodule) {
			return submodule.Name;
		} else {
			return module.Name;
		}
	};

	if (!sectionsArray || sectionsArray.length === 0) {
		return (
			<div className="player-sidebar">
				<div className="player-sidebar-header">
					<h1>{title()}</h1>
				</div>
				<div className="player-sidebar-items">
					<p>This content has no sections</p>
				</div>
			</div>
		)
	};

	return (
		<div className="player-sidebar">
			<div className="player-sidebar-header">
				<h1>{title()}</h1>
			</div>
			<div className="player-sidebar-items">
			{sectionsArray.map((section, index) => (
				<div
					key={index}
					className={`player-sidebar-item ${section.id === selectedSection ? "selected" : ""}`}
					onClick={() => handleClickSectionItem(section.id)}
				>
					<div className="player-sidebar-item-button">
						<PercentageCircle progress={section.visited ? 100 : 0} size={50} visited={/*Boolean for if the content is classes as visited*/ false} />
					</div>
					<div className="player-sidebar-item-details">
						<h4>{section.title}</h4>
					</div>
				</div>
			))}
			</div>
		</div>
	);
};

export default PlayerSidebar;
