import { useEffect } from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import "./SearchBox.scss";
import useSearchByDelay from "hooks/useSearchByDelay";

const SearchBox = ({ placeholder = "Search", handleClick, handleKeyDown = null, clear = false }) => {
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		if (clear) { 
			setSearchValue("");
		}
	}, [clear]);

	const searchByKeyDown = useCallback((value) => {
		if (handleKeyDown) {
			handleKeyDown(value);
		}
	}, [handleKeyDown]);

	const searchByClick = useCallback((value) => {
		if (value.length && handleClick) {
			handleClick(value);
		}
	}, [handleClick]);
	
	useSearchByDelay(searchValue, searchByKeyDown);
	
	const handleChangeInput = (e) => {
		setSearchValue(e.target.value);
	};

	const handleKeyDownInput = (e) => {
		if (e.key === 'Enter') {
			searchByClick(searchValue);
		}
	};

	return (
		<div className="searchbox">
			<input
				className="searchbox-input"
				type="text"
				placeholder={placeholder}
				value={searchValue}
				onChange={handleChangeInput}
				onKeyDown={handleKeyDownInput}
			/>
			<span className="searchbox-icon" onClick={() => searchByClick(searchValue)}>
				<FontAwesomeIcon icon={faMagnifyingGlass} />
			</span>
		</div>
	);
};

export default SearchBox;
