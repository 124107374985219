/**
	* @readonly
	* @enum {number}
	*/
export const ActionType = Object.freeze({
	Delete: 1,
	Resend: 2,
	Update: 3,
	Add: 4,
	DeleteGroup: 5
});