export function getFetchOptions(params) {
	if (!params) {
		params = {};
	}
	let options = {
		method: (params.method || "GET"),
		headers: {}
	};
	if ("contentType" in params) {
		if (params.contentType !== null) {
			options.headers["Content-Type"] = params.contentType;
		}
	}
	else {
		options.headers["Content-Type"] = "application/json";
		options.headers.Accept = "application/json";
	}
	if ("auth" in params) {
		if (params.auth !== null) {
			options.headers.Authorization = "Basic " + params.auth;
		}
	}
	else {
		options.headers.Authorization = "Bearer " + sessionStorage.auth;
	}
	if ("customHeader" in params) {
		options.headers["Additional-ucSKILLS-Data"] = params.customHeader;
	}
	if ("body" in params) {
		if (options.headers["Content-Type"] === "application/json") {
			options.body = JSON.stringify(params.body);
		}
		else {
			options.body = params.body;
		}
	}
	return options;
}