import { useState, useCallback, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pod from "components/Pod/Pod";
import Device from "./components/Device/Device";
import "./DeviceSelection.scss";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonType } from "components/Button/Button";
import { useModal } from "components/Modal/context/ModalContext";
import { ModalButtons } from "components/Modal/ModalButtons";
import { DataFetcher } from "components/DataFetcher";
import { getFetchOptions } from "utils/fetch";

const DeviceSelection = () => {
	const location = useLocation();
	const edit = location.state?.edit || false;
	const selectedGroup = location.state?.selectedGroup || -1;
	const custom = location.state?.custom;
	const rememberedSelectedUsers = useMemo(() => { return location.state?.selectedUsers || []; }, [location.state]); // Retain selectedUsers
	const rememberedUpdatedData = location.state?.updatedData || null;  // Retain upated editor data
	const [selectedDevices, setSelectedDevices] = useState([]);
	const [groupName, setGroupName] = useState("");
	const [manualGroupNaming, setManualGroupNaming] = useState(selectedGroup.ManualNaming || false);
	const [availableDevices, setAvailableDevices] = useState([]);
	const navigate = useNavigate(); // Added navigate hook
	const [fetchData, setFetchData] = useState({ url: `/api/v3/Devices/${selectedGroup.Id}/${custom}` });
	const initialRequest = useRef(true);
	const { openModal } = useModal();

	const setFetchRequestData = useCallback((url, options = null) => {
		setFetchData({ url: url, options: options });
	}, []);
	
	const handleReturnToUserManagement = useCallback((newId) => {
		navigate('/usermanagement', {
			state: {
				edit: edit,
				selectedUsers: rememberedSelectedUsers,
				updatedData: rememberedUpdatedData,
				newCustomDeviceGroupId: newId
			}
		});
	}, [edit, navigate, rememberedSelectedUsers, rememberedUpdatedData]);

	/**
	 * @note Fetch request different success return data:
	 *   1) GET /api/v3/Devices/{id}/{custom} - List<DeviceDto>
	 *   2) POST /api/v3/DeviceGroup - int
	 */
	const handleData = useCallback((data) => {
		if (data && initialRequest.current) {
			setAvailableDevices(data);
			setGroupName(selectedGroup.Name || selectedGroup.DeviceGroupName);
			setSelectedDevices(data.filter(device => device.Selected));
			initialRequest.current = false;
		}
		else if (typeof (data) === "number") {
			handleReturnToUserManagement(data);
		}
	}, [handleReturnToUserManagement, selectedGroup.Name, selectedGroup.DeviceGroupName]);
	
	const handleOpenSaveModal = () => {
		openModal(
			"Are you sure you want to save these changes?",
			null,
			ModalButtons.YesNo,
			handleConfirmSave,
		);
	};

	const handleError = useCallback((error) => {
		switch (error.Status) {
			case 400:
				openModal("", error.Message, null, null);
				break;
			default:
				break;
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Adding "openModal" causes an infinite loop

	const handleSetGroupName = (e) => {
		setManualGroupNaming(true);
		setGroupName(e.target.value);
	};
	
	const handleToggleDevice = (device) => {
		setSelectedDevices(prevSelectedDevices => {
			const result = prevSelectedDevices.some((selected) => selected.Id === device.Id)
				? prevSelectedDevices.filter((selected) => selected.Id !== device.Id)
				: [...prevSelectedDevices, device];
			if (!manualGroupNaming) {
				let groupName = (result.length === 1)
					? result[0]?.Name ?? ""
					: ((availableDevices.length === result.length) ? "All" : result.length) + " Devices";
				setGroupName(groupName);
			}
			return result;
		});
		setAvailableDevices(prevAvailableDevices =>
			prevAvailableDevices.map(d =>
				d.Id === device.Id ? { ...d, Selected: !d.Selected } : d
			)
		);
	};
	
	const handleSelectAll = () => {
		if (selectedDevices.length === availableDevices.length) {
			setSelectedDevices([]);
			setAvailableDevices(prevAvailableDevices =>
				prevAvailableDevices.map(device => ({ ...device, Selected: false }))
			);
		} else {
			setSelectedDevices(availableDevices);
			setAvailableDevices(prevAvailableDevices =>
				prevAvailableDevices.map(device => ({ ...device, Selected: true }))
			);
		}
	};

	const handleConfirmSave = (yes) => {
		if (yes) {
			const deviceNames = selectedDevices.map(device => device.Name).join(', ');
			console.debug(`Group Name: ${groupName}`);
			console.debug(`Selected Devices: ${deviceNames}`);
			const options = getFetchOptions({
				method: "POST",
				body: {
					GroupName: groupName,
					ManualNaming: manualGroupNaming,
					SelectedDevices: selectedDevices,
					CurrentDeviceGroupId: custom ? selectedGroup.DeviceGroupId : selectedGroup.Id
				}
			});
			setFetchRequestData("/api/v3/DeviceGroup", options);
		}
	};

	const selectAllText = selectedDevices.length === availableDevices.length ? "Deselect All Devices" : "Select All Devices";
	
	return (
		<div className="grid-two-col gutters">
			<DataFetcher fetchData={fetchData} setData={handleData} setError={handleError} />
			<Pod title="Devices">
				<div className="device-grid">
					{availableDevices.map((device) => (
						<Device
							key={device.Id}
							id={device.Id}
							name={device.Name}
							image={device.ImageLocation + device.ImageFile}
							onToggle={() => handleToggleDevice(device)}
							isSelected={device.Selected}
						/>
					))}
				</div>
			</Pod>
			<Pod title="Selected Devices" sticky>
				<div className="control-panel">
					<h3 htmlFor="groupName">Group Name</h3>
					<input
						type="text"
						id="groupName"
						maxLength="100"
						className="textbox"
						value={groupName}
						onChange={(e) => handleSetGroupName(e)}
					/>
					<h3>Selected Devices</h3>
					{selectedDevices.length > 0 ? (
						selectedDevices.map((device) => (
							<div key={device.Id} className="selected-device">
								<p>{device.Name}</p>
								<div className="selected-device-delete" onClick={() => handleToggleDevice(device)}><FontAwesomeIcon icon={faXmark} /></div>
							</div>
						))
					) : (
						<div className="no-selected-device"><p>No devices selected.</p></div>
					)}
					<div className="selected-device-button">
						<Button
							type={ButtonType.Secondary}
							text={selectAllText}
							onClick={handleSelectAll}
						/>
						<Button
							type={ButtonType.Primary}
							text="Save"
							onClick={handleOpenSaveModal}
							disabled={selectedDevices.length === 0 || !groupName || groupName.trim() === ''}
						/>
					</div>
					<div className="selected-device-button">
						<Button
							flipped
							type={ButtonType.Tertiary}
							text={"Return to User Management"}
							onClick={() => handleReturnToUserManagement(-1)}
						/>
					</div>
				</div>
			</Pod>
		</div>
	);
};

export default DeviceSelection;
