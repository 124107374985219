import { Button, ButtonType } from "components/Button/Button";
import { useState } from "react";
import "./Modal.scss";

const HeadlessSimpleModal = ({ showModal, content, closeModal }) => {
	const [show, setShow] = useState(showModal);

	const handleOnClick = () => {
		if (closeModal) {
			closeModal();
		}
		setShow(false);
	}

	if (!show) {
		return null;
	}

	return (
		<div className="modal">
			<div className="modal-backdrop clear"></div>
			<div className="modal-container">
				<div className="modal-content nogap">
					{content}
					<div className="modal-buttons">
						<Button type={ButtonType.Secondary} text="Ok" onClick={handleOnClick} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeadlessSimpleModal;