import { createContext, useState, useRef, useContext } from "react";
import { ModalButtons } from "../ModalButtons";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [modalContent, setModalContent] = useState(null);
	const [modalTitle, setModalTitle] = useState(null);
	const [modalButtons, setModalButtons] = useState(ModalButtons.Ok);
	const handleCustomCloseModal = useRef(null);

	const openModal = (title, content, buttons, onCloseModal) => {
		setModalTitle(title);
		setModalContent(content);
		setModalButtons(buttons ?? ModalButtons.Ok);
		setIsOpen(true);
		handleCustomCloseModal.current = onCloseModal;
	};

	// Function to close the modal
	const closeModal = (data) => {
		setIsOpen(false);
		setModalContent(null);
		setModalTitle(null);
		setModalButtons(ModalButtons.Ok);
		if (handleCustomCloseModal.current) {
			handleCustomCloseModal.current(data);
		}
	};

	return (
		<ModalContext.Provider value={{
			isOpen,
			openModal,
			closeModal,
			modalContent,
			modalTitle,
			modalButtons
		}}>
			{children}
		</ModalContext.Provider>
	);
};

export const useModal = () => useContext(ModalContext);
