import 'bootstrap/dist/css/bootstrap.css';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './setup/serviceWorkerRegistration';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
		<App />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();