import React from 'react';
import { Button, ButtonType } from '../Button/Button';
import './ListNavigation.scss';
import { useNavigate } from 'react-router-dom';

const ListNavigation = ({ items = [] }) => {
	const navigate = useNavigate();

	const handleButtonClick = (id) => {
		navigate(window.location.pathname, { replace: true });
		setTimeout(() => {
			navigate(`#courseId-${id}`);
		}, 10);
	};

	return (
		<div className="list-navigation">
			<div className="list-navigation-item">
				<h2>Your Courses</h2>
				{items.map(item => (
					<Button
						key={item.Id}
						text={item.Name}
						type={ButtonType.Secondary}
						onClick={() => handleButtonClick(item.Id)} 
					/>
				))}
			</div>
		</div>
	);
};

export default ListNavigation;
