import { Button, ButtonType } from "components/Button/Button";
import { DataFetcher } from "components/DataFetcher";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QuickSearch.scss";
import SearchBox from "./SearchBox";

const QuickSearch = ({ bentobox = false }) => {
	const navigate = useNavigate();
	const fetchData = { url: "/api/v3/Search/QuickSearch" };
	const [data, setData] = useState(null);
	
	const handleSearch = (searchValue) => {
		navigate("/searchresults", { state: searchValue });
	};

	return (
		<div className={`quickSearch ${bentobox ? "bentobox" : ""}`}>
			<SearchBox handleClick={handleSearch} />
			<h2>Quick Search</h2>
			<div className="quickSearch-list">
			<DataFetcher fetchData={fetchData} setData={setData}>
				{data && data.map(link => (
					<div key={link.Name} className="listButton">
						<Button to="/searchresults" state={link.Name} text={link.Name} type={ButtonType.Secondary} />
					</div>
					))}
			</DataFetcher>
			</div>
		</div>
	);
};

export default QuickSearch;
