import { DataFetcher } from "components/DataFetcher";
import Pod from "components/Pod/Pod";
import QuickSearch from "features/Search/QuickSearch";
import useUserData from "hooks/useUserData";
import { useCallback, useState } from "react";
import { UserRoles } from "utils/userRoles";
import "./Dashboard.scss";
import AdminCentre from "./components/AdminCentre";
import CourseList from "./components/CourseList";

const Dashboard = () => {
	const fetchData = { url: "/api/v3/CoursesAndModules/ByCurrentUser" };
	const [data, setData] = useState(null);
	const { userData } = useUserData();

	const handleData = useCallback((courseData) => {
		courseData.sort((a, b) => { return a.Position - b.Position });
		for (let item of courseData) {
			item.SubContent.sort((a, b) => { return a.Position - b.Position });
		}
		setData(courseData);
	}, []);

	return (
		<DataFetcher fetchData={fetchData} setData={handleData}>
			<div className="grid-two-col gutters">
				<Pod title="Courses">
					<CourseList contentItems={data} />
				</Pod>
				<div>
					<Pod title="Search">
						<QuickSearch bentobox={true} />
					</Pod>
					{userData.UserLevel !== UserRoles.Learner &&
						<Pod title="My Users">
							<AdminCentre />
						</Pod>
					}
				</div>
			</div>
		</DataFetcher>
	);
};

export default Dashboard;
