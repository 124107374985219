import { useEffect, useRef, useState } from "react";
import defaultLogo from "assets/images/bob-ucskills-logo.svg";
import defaultWaterMark from "assets/images/waterfall.svg";

const useBranding = () => {
	const cache = useRef(null);
	const [data, setData] = useState(null);
	const [whitelabelled, setWhitelabelled] = useState(false);
	const [colours, setColours] = useState({ Primary: "", Secondary: "" });
	const [logo, setLogo] = useState(defaultLogo);
	const [gradient, setGradient] = useState(false);
	const [watermark, setWatermark] = useState(defaultWaterMark);
	const [contactDetails, setContactDetails] = useState({ Number: "", Email: "" });

	useEffect(() => {
		if (cache.current) {
			setData(cache.current);
		}
		else {
			const storageData = sessionStorage.getItem("branding");
			if (storageData && storageData[0] === '{') {
				cache.current = JSON.parse(storageData);
				setData(cache.current);
			}
		}
	}, []);
	
	useEffect(() => {
		if (data) {
			setWhitelabelled(data.Whitelabelled);
			setColours({ Primary: data.PrimaryColour, Secondary: data.SecondaryColour });
			if (data.ImageFile !== "(default-asset)") {
				setLogo(data.ImageFile);
			}
			if (data.PodBackgroundImageFile !== "(default-asset)") {
				setWatermark(data.PodBackgroundImageFile);
			}
			setGradient(data.Gradient);
			setContactDetails({ Number: data.ContactNumber, Email: data.ContactEmail });
		}
	}, [data]);
	
	return { whitelabelled, colours, logo, gradient, watermark, contactDetails };
};

export default useBranding;