import React from 'react';
import './PercentageCircle.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faRotateRight } from '@fortawesome/free-solid-svg-icons';

const PercentageCircle = ({ progress, size = 60, visited = false }) => {
	const radius = 50;
	const circumference = 2 * Math.PI * radius;
	let percentage = Math.round(progress) || 0;

	const completed = () => {
		if (visited || percentage === 100) {
			return true;
		}
	}

	// Foreground circle offset (current percentage)
	const foregroundDashoffset = circumference - (percentage * 0.01) * circumference;

	// Background circle visible length and offset
	const backgroundDasharray = circumference * ((100 - percentage) * 0.01);
	const backgroundDashoffset = -circumference * (percentage * 0.01);

	const circleStyle = {
		width: `${size - 6}px`,
		height: `${size - 6}px`,
	};

	return (
		<div className="percentageCircle" style={circleStyle}>
			<svg width={size} height={size} viewBox="0 0 120 120">
				{/* Background Circle */}
				{percentage !== 100 && (
					<circle
						cx="60"
						cy="60"
						r={radius}
						fill="none"
						stroke="var(--primary-custom-color)"
						strokeWidth="10"
						strokeDasharray={backgroundDasharray}
						strokeDashoffset={backgroundDashoffset}
						transform="rotate(-90 60 60)"
					/>
				)}
				{/* Foreground Circle */}
				<circle
					cx="60"
					cy="60"
					r={radius}
					fill="none"
					stroke="var(--secondary-custom-color)"
					strokeWidth="10"
					strokeDasharray={circumference}
					strokeDashoffset={foregroundDashoffset}
					transform="rotate(-90 60 60)"
				/>
			</svg>
			{!completed() && <div className="playIcon"><FontAwesomeIcon icon={faPlay} /></div>}
			{completed() && <div className="repeatIcon"><FontAwesomeIcon icon={faRotateRight} /></div>}
		</div>
	);
};

export default PercentageCircle;
