import './GroupPill.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const colors = [
	'#d9a6a6', // Muted Coral
	'#a0b6d8', // Muted Cornflower Blue
	'#b2d8b2', // Muted Pale Green
	'#d8c687', // Muted Gold
	'#c8a0c8', // Muted Plum
	'#a6d8d9', // Muted Aqua
	'#d9a6b6', // Muted Rose
	'#a6d9a6', // Muted Mint
	'#b6a6d9', // Muted Lavender
	'#d9b6a6', // Muted Peach
	'#d9d8a6', // Muted Sand
	'#b6d9a6', // Muted Olive
	'#a6b6d9', // Muted Sky Blue
	'#d8a6c8', // Muted Pink
	'#a6d9c8', // Muted Teal
	'#d9c8a6', // Muted Tan
	'#c8d9a6', // Muted Lime
	'#a6d9b6', // Muted Sea Green
	'#d9a6d8', // Muted Orchid
	'#d9b6d8'  // Muted Lilac
];

const getColorById = (id) => {
	return colors[id % colors.length];
};

const GroupPill = ({ id, name, onRemove, disabled, hovered, onMouseEnter, onMouseLeave }) => {
	const crossColor = getColorById(id);

	return (
		<span
			className={`content-group group-${id} ${disabled ? 'disabled' : ''} ${hovered ? 'hovered' : ''}`}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<span className="cross" onClick={() => onRemove(id)} style={{ backgroundColor: crossColor }}>
				<FontAwesomeIcon icon={faXmark} />
			</span>
			<span className="content-group-text">{name}</span>
		</span>
	);
};

export default GroupPill;
