import './Device.scss';
import { Button, ButtonType } from '../../../../../components/Button/Button';
import { faCheck, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Device = ({ name, image, onToggle, isSelected }) => {

	const handleClick = (e) => {
		// Prevent event from bubbling up if the Button itself is clicked
		if (e.target.closest('.button')) {
			return;
		}
		onToggle();
	};

	return (
		<div className={`device ${isSelected ? 'selected' : ''}`} onClick={handleClick}>
			<h2>{name}</h2>
			<div className="device-image">
				{image ? (
					<img src={image} alt={name} />
				) : (
					<p>Image not available</p>
				)}
			</div>
			<div>
				<Button
					type={isSelected ? ButtonType.Primary : ButtonType.Secondary}
					text={isSelected ? 'Selected' : 'Select'}
					onClick={onToggle}  // Button's own click handler
					icon={isSelected ? faCheck : faChevronRight}
				/>
			</div>
		</div>
	);
};

export default Device;
