import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonType } from "components/Button/Button";
import "./CourseDetails.scss";

const CourseDetails = ({ course, linkage = false }) => {

	const getViewFullCourseLink = (course) => {
		let result = `/course-view#courseId-${course.Id}`;
		if (course.SubContent.length > 1) {
			result += `-moduleId-${course.SubContent[0].Id}`;
		}
		return result;
	};

	const getContinueSection = () => {
		course.hasContent = course.SubContent.some(value => value.ContentType.length);
		if (course.hasContent) {
			return (
				<div className="list-view-item-continue">
					<p><strong>{course.LastViewed.Continuing ? "Last Viewed" : "Get Started"}:</strong> {course.LastViewed.SubContentName}</p>
					<Button
						text={course.LastViewed.Continuing ? "Continue" : "Start"}
						to={`/player#courseId-${course.Id}-moduleId-${course.LastViewed.ContentId}-subModuleId-${course.LastViewed.SubContentId}`}
					/>
				</div>
			);
		}
		return null;
	};

	const content = (
		<div className="list-view-item-details">
			<div className="list-view-item-details-text">
				{linkage ? (
					<Link to={getViewFullCourseLink(course)}>
						<h2>
							{course.Name}
						</h2>
					</Link>
				) : (
					<h2>{course.Name}</h2>
				)}
				<p className="list-view-item-details-text-description">{course.Description}</p>
				{linkage && <Button to={getViewFullCourseLink(course)} type={ButtonType.Tertiary} text="View Full Course" scroll={true} />}
			</div>
			<div className="list-view-item-details-image">
				{linkage ? (
					<Link to={getViewFullCourseLink(course)}>
						<img alt={course.Name} src={course.ImageUri} />
					</Link>
				) : (
					<img alt={course.Name} src={course.ImageUri} />
				)}
			</div>
		</div>
	);

	return (
		<>
			{content}
			{getContinueSection()}
		</>
	);
};

export default CourseDetails;
