
/**
 * @readonly
 * @enum {number}
 */
export const ModalButtons = {
	None: 1,
	Ok: 2,
	YesNo: 3,
	Custom: 4
};