import "./Footer.scss";
import { Link } from "react-router-dom";
import logo from "assets/images/bob-ucskills-logo.svg";

const Footer = ({ hideFooter, whitelabelled, loggedIn = true }) => {
	if (hideFooter) {
		return null;
	}

	return (
		<div className="footer gutters">
			<div></div>
			<div className="footer-links">
				<a href="/site/legal/privacy-policy.html" target="_blank">
					<p>Privacy Policy</p>
				</a>
				<a href="/site/legal/terms-and-conditions.html" target="_blank">
					<p>Terms & Conditions</p>
				</a>
				{loggedIn ?
					<Link to="/help"><p>Contact Us</p></Link>
					:
					<a href="mailto:support@ucskills.com">
						<p>Contact Us</p>
					</a>
				}
			</div>
			<div className="footer-logo">
				{whitelabelled && <p>Powered by <img alt="UCSkills" src={logo} /></p>}			
			</div>
		</div>
	);
};

export default Footer;
