import { useCallback } from "react";
import useFetch from "hooks/useFetch";
import { getFetchOptions } from "utils/fetch";

const useLogout = (navigate) => {
	const { execute } = useFetch();

	const logout = useCallback(() => {
		const options = getFetchOptions({
			method: "POST"
		});
		execute("api/Logout/", options, () => {
			sessionStorage.clear();
			if (navigate) {
				navigate("/", { replace: true });
			}
			else {
				window.location.replace("/");
				console.warn("Navigation occurred outside of react-router-dom technology... navigate function has not been passsed into useLogout hook.")
			}
		});
	}, []);

	return { logout };
};

export default useLogout;