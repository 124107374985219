import { useCallback, useEffect, useRef } from "react";

// https://www.youtube.com/watch?v=0c6znExIqRw&list=PLZlA0Gpn_vH_NT5zPVp18nGe_W9LqBDQK&index=28
const useTimeout = (delay, callback) => {
	const callbackRef = useRef(callback);
	const timeoutRef = useRef();

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	const start = useCallback(() => {
		timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
	}, [delay]);

	const stop = useCallback(() => {
		timeoutRef.current && clearTimeout(timeoutRef.current);
	}, []);

	useEffect(() => {
		start();
		return stop;
	}, [delay, start, stop]);

	const reset = useCallback(() => {
		stop();
		start();
	}, [stop, start]);

	return { stop, reset };
};

export default useTimeout;