import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "features/Header/Header";
import BackToTop from "components/BackToTop/BackToTop";
import Modal from "components/Modal/Modal";
import Footer from "features/Footer/Footer";
import "./Layout.scss";
import useBranding from "hooks/useBranding";
import WelcomeModal from "features/Welcome/WelcomeModal";

const Layout = () => {
	const location = useLocation();
	const { whitelabelled, colours } = useBranding();
	const [showWelcomeModal, setShowWelcomeModal] = useState(false);
	const [newUser, setNewUser] = useState(true);
	
	useEffect(() => {
		const firstLogin = sessionStorage.getItem('firstLogin') === 'true';
		setNewUser(firstLogin);
		const showWelcome = sessionStorage.getItem('showWelcome') === 'true';
		setShowWelcomeModal(showWelcome);
	}, []);

	const handleCloseWelcomeModal = () => {
		setShowWelcomeModal(false);
	};

	const rootStyle = () => {
		if (colours.Primary) {
			return (
				<style>
					{`:root { 
                      --primary-custom-color: ${colours.Primary}; 
                      --secondary-custom-color: ${colours.Secondary};
                    }`}
				</style>
			);
		}
		return null;
	};

	// List of paths where the footer should be hidden
	const hideFooter = location.pathname === "/player";

	// List of paths where the header should be in wide form
	const wideHeader = location.pathname === "/player";

	return (
		<div className="layout-container">
			{rootStyle()}
			{showWelcomeModal && <WelcomeModal onClose={handleCloseWelcomeModal} newUser={newUser} />}
			<Header wideHeader={wideHeader} />
			<div className="content">
				<Outlet />
			</div>
			<BackToTop />
			<Modal />
			<Footer hideFooter={hideFooter} whitelabelled={whitelabelled} />
		</div>
	);
};

export default Layout;
