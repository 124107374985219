import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./PlayerBreadcrumb.scss";

const PlayerBreadcrumb = ({ course, module, submodule, setBreadcrumbHeight }) => {
	const breadcrumbRef = useRef(null);

	useEffect(() => {
		if (breadcrumbRef.current) {
			const height = breadcrumbRef.current.offsetHeight;
			setBreadcrumbHeight(height);
		}
	}, [course, module, submodule, setBreadcrumbHeight]);

	return (
		<div className="player-breadcrumb" ref={breadcrumbRef}>
			<p>
				{course && <Link to={`/course-view#courseId-${course.Id}`}>{course.Name}</Link>}
				{module && <> <FontAwesomeIcon icon={faChevronRight} size='xs' /> <Link to={`/course-view#courseId-${course.Id}-moduleId-${module.Id}`}> {module.Name}</Link></>}
				<span>{submodule && <> <FontAwesomeIcon icon={faChevronRight} size='xs' /> {submodule}</>}</span> 
			</p>
		</div>
	);
};

export default PlayerBreadcrumb;
