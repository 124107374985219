import { useEffect, useState } from "react";
import { DataTable as PrTable } from "primereact/datatable";
import SearchBox from "features/Search/SearchBox";
import { Column } from "primereact/column";
import "./DataTable.scss";

/**
 * Properties Object definition used in the DataTable component.
 * @typedef {Object} DataTableProps
 * @property {Array.<Object>} columns
 * @property {Array.<Object>} rows
 * @property {string} columnIdName
 * @property {Function} onRowClick
 * @property {number} [rowsPerPage=10] - Number of rows per page (optional, default is 10)
 * @property {boolean} [useCardView=false] - Whether to use the card view for mobile
 */

/**
 * @component
 * @param {DataTableProps} props
 */

const DataTable = (props) => {
	const [clearSearch, setClearSearch] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	// Handle screen resize to detect mobile view
	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 768);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (props.filters && props.filters["global"] && props.filters["global"].value !== null) {
			if (!props.filters["global"].value.length) {
				setClearSearch(true);
			}
		}
	}, [props.filters]);

	// If you don't want a col to appear on mobile, use the class remove-on-mobile on it
	const renderCards = () => {
		return props.rows.map((row) => (
			<div key={row[props.columnIdName]} className="data-card">
				{props.columns
					.filter((col) => {
						if (col.headerName && col.headerName.props && col.headerName.props.className) {
							if (col.headerName.props.className.includes('remove-from-card')) {
								return false;
							}
						}
						if (col.valueGetter && typeof col.valueGetter === 'function') {
							const valueContent = col.valueGetter(row);
							if (valueContent && valueContent.props && valueContent.props.className) {
								if (valueContent.props.className.includes('remove-from-card')) {
									return false;
								}
							}
						}
						return true;
					})
					.map((col) => (
						<div key={col.field} className="data-card-field">
							<strong>{col.headerName}: </strong>
							{col.valueGetter ? col.valueGetter(row) : row[col.field]}
						</div>
					))}
			</div>
		));
	};

	if ((props.rows) && (props.rows.length)) {
		const { rowsPerPage = 10 } = props;
		const rowsPerPageOptions = [rowsPerPage, 25, 100].sort((a, b) => a - b);

		const handleKeyDown = (searchValue) => {
			setClearSearch(false);
			if (props.handleFilter) {
				props.handleFilter(searchValue);
			}
		};

		const renderHeader = () => {
			if (!props.filters) {
				return (<></>);
			}
			return (
				<SearchBox clear={clearSearch} placeholder="Search Timeline" handleKeyDown={handleKeyDown} />
			);
		};

		const tableProps = {
			value: props.rows,
			paginator: props.rows.length > rowsPerPage,
			filters: props.filters,
			globalFilterFields: props.globalFilterFields,
			expandedRows: props.expandedRows,
			onRowToggle: props.onRowToggle,
			rowExpansionTemplate: props.rowExpansionTemplate,
			removableSort: true,
			rows: rowsPerPage,
			rowsPerPageOptions: rowsPerPageOptions,
			header: renderHeader,
			paginatorTemplate: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
			currentPageReportTemplate: "{first} - {last} of {totalRecords}",
			rowClassName: props.rowClassName
		};

		if (props.onRowClick) {
			tableProps.onRowClick = props.onRowClick;
			tableProps.selectionMode = "single";
		}

		return (
			<div className={`data-table-container ${props.useCardView ? 'use-card-view' : ''}`}>
				{/* Conditionally render DataTable or DataCards based on screen size and useCardView prop */}
				{isMobile && props.useCardView ? (
					<div className="data-cards-container">{renderCards()}</div>
				) : (
					<PrTable className="data-table" {...tableProps}>
						{props.columns.map((col, i) => (
							(col.expander)
								? (<Column key={col.field || i} expander={col.expander} headerClassName={col.class} style={col.style} />)
								: (
									<Column
										key={col.field || i}
										field={col.field}
										sortable={col.sortable !== undefined ? col.sortable : false}
										header={col.headerName}
										body={(rowData) => {
											// Check if valueGetter returns a string (assumed HTML) or a React component/element
											if (col.valueGetter) {
												const cellContent = col.valueGetter(rowData);
												return typeof cellContent === 'string' ? (
													<span dangerouslySetInnerHTML={{ __html: cellContent }} />
												) : (
													cellContent
												);
											}
										}}
										headerClassName={col.class}
										style={col.style}
									/>)
						))}
					</PrTable>
				)}
			</div>
		);
	}
	return null;
};

export default DataTable;

