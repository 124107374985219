import "./CourseItem.scss";
import ModuleItem from "./ModuleItem";
import CourseDetails from "components/CourseDetails/CourseDetails";

const CourseItem = ({ course, expandedModuleId, onExpand }) => {

	return (
		<div className="list-view-item" id={"courseId-" + course.Id}>
			<CourseDetails course={course} />
			<h3>Modules</h3>
			{course.SubContent.map((module) => (
				<ModuleItem
					key={module.Id}
					course={course}
					module={module}
					expandedModuleId={expandedModuleId}
					onExpand={onExpand}
				/>
			))}
		</div>
	);
};

export default CourseItem;
