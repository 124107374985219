import { useCallback, useEffect } from "react";
import useTimeout from "./useTimeout";

const useSearchByDelay = (searchValue, onSearch) => {
	const KEYSTROKE_DELAY = 400;
	const { stop, reset } = useTimeout(KEYSTROKE_DELAY, () => searchRequest(searchValue));
	useEffect(reset, [searchValue, reset]);
	useEffect(stop, []); // To ensure the timeout is stopped on component unmount

	const searchRequest = useCallback((value) => {
		onSearch(value);
	}, [onSearch]);
};

export default useSearchByDelay;