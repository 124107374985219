import { useCallback, useEffect, useState, useRef } from "react";
import "components/Modal/Modal.scss";
import useBranding from "hooks/useBranding";
import { Button, ButtonType } from "components/Button/Button";
import { DataFetcher } from "components/DataFetcher";
import { getFetchOptions } from "../../utils/fetch";
import "./WelcomeModal.scss";

const WelcomeModal = ({ onClose, newUser }) => {
	const { gradient, watermark } = useBranding();
	const [packageName, setPackageName] = useState("");
	const [fetchData, setFetchData] = useState({ url: "/api/v3/Welcome/PackageName" });
	const welcomeFlagUpdated = useRef(false);
	
	const setFetchRequestData = useCallback((url, options = null) => {
		setFetchData({ url: url, options: options });
	}, []);

	/**
	 * @note Fetch request different success return data:
	 *   1) GET /api/v3/Welcome/PackageName - string
	 *   2) POST /api/v3/Welcome/UpsertUserWelcomeFlag - TRUE
	 */
	const handleData = useCallback((data) => {
		if (data && !welcomeFlagUpdated.current) {
			const options = getFetchOptions({ method: "POST" });
			sessionStorage.showWelcome = false;
			setFetchRequestData("/api/v3/Welcome/UpsertUserWelcomeFlag", options);
			welcomeFlagUpdated.current = true;
			if (typeof (data) === "string") {
				setPackageName(` ${data}`);
			}
		}
	}, [setFetchRequestData]);

	// Prevent page scrolling when the modal is open
	useEffect(() => {
		document.body.classList.add('no-scroll');

		return () => {
			document.body.classList.remove('no-scroll');
		};
	}, []);

	// Define content for new user and returning user
	const newUserContent = (
		<>
			<p className="mb16">Access video tutorials and interactive eLearning content for your{packageName} phone system. From your initial set up, to making changes to its configuration at any time.</p>
			<p className="mb16">Select the "Start" button on your course to jump right into the introduction or manage your team in the "My Users" tile.</p>
		</>
	);

	const returningUserContent = (
		<>
			<p className="mb16">All your{packageName} eLearning content and functionality is here in a new and easy-to-use interface.</p>
			<p className="mb16">Select the "Continue" button on your course to pick up where you left off or "Start" to jump into a new course. "View Full Course" lets you browse your course content.</p>
			<p className="mb16">Manage your users from our brand new "My Users" tile to simply add users and assign learning content.</p>
		</>
	);

	const welcomeModalContent = newUser ? newUserContent : returningUserContent;

	return (
		<div className="modal is-open">
			<DataFetcher fetchData={fetchData} setData={handleData} >
			<div className="modal-backdrop"></div>
			<div className="modal-container">
				<div className="modal-header">
					<h1>{newUser ? "Welcome to ucSKILLS" : "Welcome to your new-look ucSKILLS"}</h1>
					{watermark && <img alt="Pod Background Detail" className="podHeader-image" src={watermark} />}
					{gradient && <span className="podHeader-grad"></span>}
				</div>
				<div className="modal-content">
					{welcomeModalContent}
					<div className="modal-buttons">
						<Button type={ButtonType.Primary} text="Ok" onClick={onClose} />
					</div>
				</div>
				</div>
			</DataFetcher>
		</div>
	);
};

export default WelcomeModal;
