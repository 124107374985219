import SearchResultItem from "./SearchResultItem";
import "./SearchResultsList.scss";

const SearchResultsList = ({ searchTerm, searchResults }) => {

	return (
		<div className="searchResultsList">
			<div className="searchResultsList-searchterm">
				<h2>Search Term: {searchTerm}</h2>
			</div>
			<SearchResultItem searchResults={searchResults} searchTerm={searchTerm}></SearchResultItem>
		</div>
	);
};

export default SearchResultsList;
