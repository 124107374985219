import { useEffect } from 'react';
import { useModal } from 'components/Modal/context/ModalContext';
import './Modal.scss';
import useBranding from "hooks/useBranding";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonType } from "components/Button/Button";
import { ModalButtons } from "./ModalButtons";

///////////////USAGE////////////////

//const { openModal } = useModal();

//const modalTitle = "Unlock Modules"
//const modalBasicButton = true;

//const modalContent = (
//	<div>
//		<p>Modal Content</p>
//		<div className="modal-buttons">
//			{ your custom buttons (remember to set the basic buttons to false) }
//		</div >
//	</div>
//);

//const handleOpenModal = () => {
//	openModal(modalTitle, modalContent, modalBasicButton);
//};

const Modal = () => {
	const {
		isOpen,
		closeModal,
		modalContent,
		modalTitle,
		modalButtons,
		customModalButtonContent
	} = useModal();

	const { gradient, watermark } = useBranding();

	// Prevent page scrolling when the modal is open
	useEffect(() => {
		if (isOpen) {
			document.body.classList.add('no-scroll');
		} else {
			document.body.classList.remove('no-scroll');
		}

		// Cleanup when the component is unmounted
		return () => {
			document.body.classList.remove('no-scroll');
		};
	}, [isOpen]);

	if (!isOpen) {
		return null;
	}

	const renderButtons = () => {
		if (modalButtons === ModalButtons.Ok) {
			return (
				<div className="modal-buttons">
					<Button type={ButtonType.Secondary} text="Ok" onClick={closeModal} />
				</div>
			);
		}
		if (modalButtons === ModalButtons.YesNo) {
			return (
				<div className="modal-buttons">
					<Button type={ButtonType.Secondary} text="No" onClick={() => closeModal(false)} />
					<Button type={ButtonType.Primary} text="Yes" onClick={() => closeModal(true)} />
				</div>
			);
		}
		if (modalButtons === ModalButtons.Custom && customModalButtonContent) {
			return <div className="modal-buttons">{customModalButtonContent}</div>;
		}
		return null;
	};

	return (
		<div className="modal">
			<div className="modal-backdrop" onClick={() => closeModal(false)}></div>
			<div className={`modal-container ${modalButtons === ModalButtons.YesNo ? 'yesno' : ''}`}>
				<div className="modal-header">
					<h1>{modalButtons === ModalButtons.YesNo ? "Are you sure?" : modalTitle}</h1>
					{watermark && <img alt="Pod Background Detail" className="podHeader-image" src={watermark} />}
					{gradient && <span className="podHeader-grad"></span>}
					<button className="modal-close" onClick={() => closeModal(false)}><FontAwesomeIcon icon={faXmark} /></button>
				</div>
				<div className="modal-content">
					{modalContent}
					{renderButtons()}
				</div>
			</div>
		</div>
	);
};

export default Modal;
