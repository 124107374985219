import { ModalProvider } from "./components/Modal/context/ModalContext";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import Layout from "./components/Layout";
import { LoadingProvider } from "./components/LoadingSpinner/LoadingSpinner";
import Login from "./features/Login/Login";
import AppRoutes from "./setup/AppRoutes";
import "./ucskills.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const App = () => {
	let routes = createRoutesFromElements(
		<Route path="/">
			<Route index element={<Login />} />
			<Route element={<Layout />}>
				{AppRoutes.map((route, index) => {
					const { element, ...rest } = route;
					return <Route key={index} {...rest} element={element} />;
				})}
			</Route>
		</Route>
	);

	const router = createBrowserRouter(routes);
	return (
		<ModalProvider>
			<LoadingProvider>
				<RouterProvider router={router} />
			</LoadingProvider>
		</ModalProvider>
	);

};

export default App;
