/**
	* @readonly
	* @enum {number}
	*/
export const PackageType = Object.freeze({
	Invalid: 0,
	SCORM_12: 1,
	SCORM_2004v2: 2,
	SCORM_2004v3: 3,
	xAPI: 4,
	xAPI_CAPTIVATE: 5
});

export function packageIsScorm(packageType) {
	return (packageType === PackageType.SCORM_12 || packageType === PackageType.SCORM_2004v2 || packageType === PackageType.SCORM_2004v3);
}