import React from 'react';
import './UserEditor.scss';
import { Button, ButtonType } from 'components/Button/Button';

const ActionButtons = ({ edit, users, deleteData, resendData, handleSubmitClick, canSave }) => {
	const getDeleteButtonText = () => {
		return (deleteData.disabled) ? "You cannot delete yourself" : `Delete ${users.length} User${users.length > 1 ? "s" : ""}`;
	};

	return (
		<div className="modal-buttons">
			{edit && (
				<>
					<Button
						type={ButtonType.Delete}
						text={getDeleteButtonText()}
						onClick={() => deleteData.action()}
						disabled={deleteData.disabled}
					/>
					<Button
						type={ButtonType.Secondary}
						text="Resend Registration"
						onClick={() => resendData.action()}
						disabled={resendData.disabled}
					/>
				</>
			)}
			<Button
				type={ButtonType.Primary}
				text="Save"
				onClick={handleSubmitClick}
				disabled={!canSave}
			/>
		</div>
	);
};

export default ActionButtons;
