import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowUp } from '@fortawesome/free-solid-svg-icons';
import './BackToTop.scss';

const BackToTop = () => {
	const [isVisible, setIsVisible] = useState(false);

	const toggleVisibility = () => {
		if (window.pageYOffset > 67) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
		if (window.location.hash) {
			window.history.replaceState(null, '', window.location.pathname + window.location.search);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);
		return () => {
			window.removeEventListener('scroll', toggleVisibility);
		};
	}, []);

	return (
		<div className="backToTop">
			{isVisible &&
				<div onClick={scrollToTop} className="backToTopIcon">
					<FontAwesomeIcon icon={faCircleArrowUp} />
				</div>
			}
		</div>
	);
};

export default BackToTop;
