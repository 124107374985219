import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useBranding from "hooks/useBranding";
import "./DisplaySeats.scss";

const DisplaySeats = ({ maxSeats, usedSeats }) => {
	const { contactDetails } = useBranding();
	const barWidthPercent = (usedSeats / maxSeats) * 100;

	return (
		<div className="displaySeats">
			<h3>
				You have {maxSeats - usedSeats} seat(s) remaining
				<span className="popup-container">
					<FontAwesomeIcon icon={faCircleInfo} className="info-icon" />
					<div className="popup">
						<p>To add more seats to your package, please contact us.</p>
						<p>
							<strong>{contactDetails.Number}<br/>{contactDetails.Email}</strong>
						</p>
					</div>
				</span>
			</h3>
			<div className="displaySeats-amount">
				<span className="displaySeats-amount-bar" style={{ width: `${barWidthPercent}%` }}></span>
				<p>{usedSeats} / {maxSeats}</p>
			</div>
		</div>
	);
};

export default DisplaySeats;
