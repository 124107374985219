import { useCallback, useRef, useState } from "react";
import { getFetchOptions } from "../utils/fetch.js";

/**
 * @typedef {Object} FetchState
 * @property {boolean} ready
 * @property {boolean} loading
 * @property {{status: number, message: string}} error
 * @property {string} data
 * @property {Function} execute
 */

/**
 * This hook wraps the fetch API and is used to perform requests to the server on demand, typically POST, PUT and DELETE requests.
 * @returns {FetchState} State data.
 */
const useFetch = () => {
	const [executing, setExecuting] = useState(false);
	const error = useRef(null);
	const [data, setData] = useState(null);

	const execute = useCallback(async (url, options, onComplete) => {
		const performFetch = async () => {
			error.current = null;
			setExecuting(true);
			if (!options) {
				options = getFetchOptions(); // GET with Bearer authorization
			}
			const response = await fetch(url, options);
			let content = await response.text();
			if (content.length !== 0) {
				// Attempt to convert if the response is valid JSON.
				try {
					content = JSON.parse(content);
					content.Status = response.status;
				}
				catch (ex) {
					if (response.status !== 200) {
						error.current = {
							Message: content,
							Status: response.status
						};
					}
				}
			}
			else {
				content = {
					Message: response.statusText,
					Status: response.status,
					PollUrl: (response.status === 202) ? response.headers.get("location") : null
				};
			}
			if ((response.status >= 200) && (response.status < 300)) {
				setData(content);
			}
			else if (!error.current) {
				if (typeof (content) === "string") {
					content = {
						Status: response.status,
						Message: content
					}
				}
				error.current = content;
			}

			setExecuting(false);
			if (onComplete) {
				onComplete(content, error.current);
			}
		};

		performFetch();
	}, [setData]);

	return {
		executing,
		error: error.current,
		data,
		execute
	};
};

export default useFetch;