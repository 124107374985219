import "./WaitSpinner.scss";

const WaitSpinner = () => {
  return (
    <div className="please-wait">
      <div className="please-wait-image">
      </div>
    </div>
  );
};

export default WaitSpinner;