const strings = {
	LOGIN_TITLE: "Login to ucSKILLS",
	EMAIL_ADDRESS: "Email Address",
	PASSWORD: "Password",
	FORGOT_PASSWORD: "Forgot your Password?",
	CLICK_LOGIN: "Click to login",
	CLICK_SHOW_PASSWORD: "Click to show the password",
	CLICK_HIDE_PASSWORD: "Click to hide the password",
	TERMS_AND_CONDITIONS: "Terms&nbsp;and&nbsp;Conditions",
	PRIVACY_POLICY: "Privacy&nbsp;Policy",
	CONTACT_US: "Contact&nbsp;Us",
	ERROR: "An error has occurred...",
	ERR_USER_EMPTY: "You have not entered a Username",
	ERR_PASSWORD_EMPTY: "You have not entered a password",
	ERR_PASSWORD_TOOSHORT: "Your password must be between 8 and 64 characters.",
	ERR_AUTHORIZATION_SCHEME: "The Scheme used to authorize your credentials is invalid.",
	ERR_REGISTRATION_USER_DISABLED: "Unfortunately, your account was disabled prior to registration so you cannot log in.<br />Please contact iTEL for assistance.",
	ERR_USER_OR_PASSWORD: "Your username and/or password do not match",
	ERR_USER_LOCKED_OUT: "Unable to log you in because your account is locked out.<br />Please request a password reset using the 'Forgot your Password?' link above.",
	ERR_COMPANY_DISABLED: "Unable to log you in because your account has been disabled.<br />Please contact iTEL for assistance.",
	ERR_USER_DISABLED: "Unable to log you in because your account has been disabled.<br />Please contact your administrator for assistance."
};

export default strings;